import { Controller } from '@hotwired/stimulus'
import {
  generatePresignedUrl,
  uploadFileInForm,
  areAllFilesUploaded,
} from '../../modules/s3_uploader'

export default class extends Controller {
  static targets = ['supplierForm']
  connect() {
    this.supplierFormTarget.addEventListener(
      'submit',
      this.submitForm.bind(this),
    )

    // bind event to new added field
    document.querySelectorAll('input[type=file]').forEach((fileInput) => {
      fileInput.addEventListener('change', (e) => {
        generatePresignedUrl('supplier_documents/', e.target)
      })
    })
  }

  submitForm(e) {
    const form = e.currentTarget

    // check if all files are uploaded
    if (areAllFilesUploaded(form)) {
      form.submit.click()
      return
    }
    e.preventDefault()

    uploadFileInForm(form, () => {
      form.submit.disabled = false
      form.submit.click()
    })

    form.submit.disabled = true
  }
}
