import { Controller } from '@hotwired/stimulus'
import $ from 'jquery'

export default class extends Controller {
  static targets = ['hiddenScore', 'btnScore']

  connect() {
    console.log('connected!!! yayyy')
  }

  selectScore(event) {
    event.preventDefault()

    if (event.target.classList.contains('btn-success')) {
      event.target.classList.toggle('btn-success')
      event.target.classList.toggle('btn-outline-primary')
      this.hiddenScoreTarget.value = ''
    } else {
      $('.btnScore.btn-success')
        .removeClass('btn-success')
        .addClass('btn-outline-primary')

      this.hiddenScoreTarget.value = event.target.getAttribute('data-score')

      event.target.classList.toggle('btn-outline-primary')
      event.target.classList.toggle('btn-success')
    }
  }
}
