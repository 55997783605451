import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['']
  static values = {
    repaidChartOpts: Object,
  }

  connect() {
    this._loadChart()
  }

  _loadChart() {
    // console.log(this.repaidChartOptsValue)
    HSCore.components.HSChartJS.init(
      document.querySelector('.js-chartjs-doughnut-half'),
      {
        options: {
          plugins: {
            tooltip: {
              postfix: '%',
            },
          },
          cutout: '85%',
          rotation: '270',
          circumference: '180',
        },
      },
    )
  }
}
