import { Controller } from '@hotwired/stimulus'
import { loadPhoneInput } from '../../modules/phone_input'
import {
  generatePresignedUrl,
  uploadFileInForm,
  areAllFilesUploaded,
} from '../../modules/s3_uploader'

export default class extends Controller {
  static targets = [
    'stepForm',
    'stepFormReady',
    'orderFile',
    'orderNumber',
    'totalAmount',
    'vendorCompanyName',
    'vendorCompanyEmail',
    'vendorCompanyAddress',
    'vendorCompanyPhone',
    'orderFilePreview',
    'orderNumberPreview',
    'totalAmountPreview',
    'vendorCompanyNamePreview',
    'vendorCompanyEmailPreview',
    'vendorCompanyAddressPreview',
    'vendorCompanyPhonePreview',
    'vendorCompanyPhonePreview',
    'errorMsg',
    'validMsg',
  ]
  connect() {
    // INITIALIZATION OF STEP FORM
    // =======================================================
    const self = this

    this.stepForm = new HSStepForm('.js-step-form', {
      onNextStep: function () {
        // Update summary
        self.fillDataToSummary()

        // Auto save form data
        self.uploadFormData()
      },
    })

    this.stepFormTarget.addEventListener('submit', this.submitForm.bind(this))

    if (this.hasVendorCompanyPhoneTarget && !this.iti) {
      this.iti = loadPhoneInput(
        this.vendorCompanyPhoneTarget,
        this.data.get('country'),
        this.errorMsgTarget,
        this.validMsgTarget,
      )
    }

    // bind event to new added field
    this.orderFileTarget.addEventListener('change', (e) => {
      generatePresignedUrl('orders/', e.target, function (data) {
        // Upload after presigned available
        uploadFileInForm(self.stepFormTarget, () => {
          self.appendUploadedFileToForm(self.stepFormTarget)
        })
      })
    })
  }

  fillDataToSummary() {
    if (this.orderFileTarget.files[0]) {
      this.orderFilePreviewTarget.innerHTML = this.orderFileTarget.files[0].name
    }

    this.orderNumberPreviewTarget.innerHTML = this.orderNumberTarget.value
    this.totalAmountPreviewTarget.innerHTML = this.totalAmountTarget.value
    this.vendorCompanyNamePreviewTarget.innerHTML =
      this.vendorCompanyNameTarget.value
    this.vendorCompanyEmailPreviewTarget.innerHTML =
      this.vendorCompanyEmailTarget.value
    this.vendorCompanyAddressPreviewTarget.innerHTML =
      this.vendorCompanyAddressTarget.value
    this.vendorCompanyPhonePreviewTarget.innerHTML =
      this.vendorCompanyPhoneTarget.value
  }

  appendUploadedFileToForm(form) {
    const fileElement = form.querySelector('.s3-upload-item')
    const fileData = JSON.parse(fileElement.getAttribute('data-file'))

    const inputElement = document.createElement('input')
    inputElement.type = 'hidden'
    inputElement.name = 'order_file'
    inputElement.value = fileData.key

    form.appendChild(inputElement)
  }

  isReadyStep() {
    return this.stepFormReadyTarget.classList.contains('active')
  }

  submitForm(e) {
    const form = e.currentTarget
    e.preventDefault()

    if (!this.formReady && this.isReadyStep()) {
      this.formReady = true
      return
    }

    // // need to trigger this because we overrided form submission here
    // // to assign full number to hidden input
    this.iti._handleHiddenInputSubmit()

    // check if all files are uploaded
    if (areAllFilesUploaded(form)) {
      form.submit()
      return
    }

    const self = this
    uploadFileInForm(form, () => {
      // Create order_file input
      self.appendUploadedFileToForm(form)

      form.submit.disabled = false
      form.submit()
    })
    form.submit.disabled = true
  }

  uploadFormData() {
    // Submit the form data via AJAX
    const form = this.stepFormTarget
    fetch(form.action, {
      method: form.method,
      body: new FormData(form),
      headers: {
        'x-requested-with': 'XMLHttpRequest',
      },
    })
      .then((response) => response.text())
      .then((newContent) => {})
      .catch((error) => {
        console.error('Error:', error)
      })
  }
}
