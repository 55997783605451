import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['']

  connect() {
    this.setTimeZone()
  }

  setTimeZone() {
    const timezoneElement = document.getElementById('id_timezone')
    if (!timezoneElement) return

    var userTimeZone = this.userTimeZoneByIANA()

    if (!userTimeZone) {
      userTimeZone = this.userTimeZoneByOffset()
    }

    if (userTimeZone) {
      timezoneElement.value = userTimeZone
    }
  }

  userTimeZoneByOffset() {
    const offset = new Date().getTimezoneOffset()

    const offsetMap = {
      '-420': 'Asia/Bangkok', // UTC+7 for Bangkok, Hanoi, Ho Chi Minh
      '-480': 'Asia/Singapore', // UTC+8 for Singapore
    }

    return offsetMap[offset.toString()]
  }

  userTimeZoneByIANA() {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

    return this.convertTimeZone(timeZone)
  }

  // The Asia/Saigon time zone has been deemed obsolete
  // But it's still return by Intl.DateTimeFormat().resolvedOptions().timeZone
  // In some browsers
  convertTimeZone(timeZone) {
    if (timeZone == 'Asia/Saigon') {
      return 'Asia/Ho_Chi_Minh'
    }

    return timeZone
  }
}
