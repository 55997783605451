import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'underwritingFormWrapper',
    'underwritingForm',
    'documentFile',
  ]

  connect() {
    this.bindHandleEvent()
  }

  bindHandleEvent() {
    // INITIALIZATION OF DROPZONE
    // =======================================================
    this.handleDragAndDropDocument()

    this.underwritingFormTarget.addEventListener(
      'submit',
      this.submitForm.bind(this),
    )

    if (this.companySelectionTarget) $(this.companySelectionTarget).select2()
  }

  handleDragAndDropDocument() {
    const self = this

    HSCore.components.HSDropzone.init('.js-dropzone', {
      autoProcessQueue: false,
      uploadMultiple: false,
      binaryBody: true,
      parallelUploads: 999,
      thumbnailWidth: 200,
      thumbnailHeight: 200,
      accept: function (file, done) {
        self.handlePDFPreview(file)

        const prefix = 'bank_statements/'
        const file_name = encodeURIComponent(file.name)
        const file_type = encodeURIComponent(file.type)

        fetch(
          `/uploader/presign_s3/?file_name=${file_name}&file_type=${file_type}&prefix=${prefix}`,
        )
          .then((response) => response.json())
          .then((data) => {
            file.uploadURL = data.presigned_url
            file.key = data.key
            done()
          })
      },
      sending: function (file, xhr, formData) {
        // Overwrite Dropzone's default upload URL with our pre-signed URL
        xhr.open('PUT', file.uploadURL, true)

        // Send file as binary data
        var _send = xhr.send
        xhr.send = function () {
          _send.call(xhr, file)
        }
      },
      complete: function (file) {
        const inputElement = document.createElement('input')
        inputElement.type = 'hidden'
        inputElement.name = 'bank_statements'
        inputElement.value = JSON.stringify({
          key: file.key,
          file_name: file.name,
        })
        self.underwritingFormTarget.appendChild(inputElement)

        // submit form if all files are uploaded
        if (self.areAllFilesUploaded(this)) {
          self.underwritingFormTarget.submit.disabled = false
          self.underwritingFormTarget.submit.click()
        }
      },
    })
  }

  submitForm(event) {
    const dropzone = Dropzone.forElement('.js-dropzone')

    if (dropzone.files.length == 0) {
      alert('No document selected. Please upload at least one.')
      event.preventDefault()
      return
    }
    this.underwritingFormTarget.submit.disabled = true

    if (!this.areAllFilesUploaded(dropzone)) {
      // submit uploading files
      dropzone.processQueue()
      event.preventDefault()
      return
    }
  }

  handlePDFPreview(file) {
    if (!file.type.startsWith('application/pdf')) return

    const reader = new FileReader()
    reader.onload = function (event) {
      const blob = new Blob([event.target.result], { type: file.type })
      const blobUrl = URL.createObjectURL(blob)

      // Display the Blob using the blobUrl
      const previewer = document.createElement('embed')
      previewer.src = blobUrl + '#toolbar=0'
      previewer.type = 'application/pdf'
      previewer.classList.add('file-preview')

      const dzImgElement = file.previewElement.querySelector('.dz-img')
      if (dzImgElement) {
        dzImgElement.appendChild(previewer)
      }
    }
    reader.readAsArrayBuffer(file)
  }

  areAllFilesUploaded(dropzone) {
    if (dropzone.getQueuedFiles().length > 0) {
      return false
    }

    if (dropzone.getUploadingFiles().length > 0) {
      return false
    }

    return true
  }
}
