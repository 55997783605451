import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['']

  connect() {
    this.checkStatus()
  }

  checkStatus() {
    // Make an AJAX request to fetch the status from the endpoint.
    fetch('/my-info/status')
      .then((response) => response.json())
      .then((data) => {
        // Check if the status is final (success or error).
        if (data.status === 'success' || data.status === 'error') {
          // You've received a final status, redirect to edit page.
          window.location.href = '/kycs/kyc-my-info-confirm'
        }

        // If not final, continue checking after a delay (e.g., every 2 seconds).
        setTimeout(() => {
          this.checkStatus()
        }, 2000) // Adjust the delay as needed.
      })
      .catch((error) => {
        // Handle any errors from the AJAX request here.
        console.error('Error fetching status:', error)
      })
  }
}
