import { Controller } from '@hotwired/stimulus'
import $ from 'jquery'

export default class extends Controller {
  static targets = ['']

  connect() {
    this._showModal()
  }

  _showModal() {
    $('#npsSurveyModalBtn').click()
  }
}
