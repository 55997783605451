import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input']

  connect() {}

  copy() {
    const inputElement = this.inputTarget
    inputElement.select()
    inputElement.setSelectionRange(0, 99999) // For mobile devices
    document.execCommand('copy')
    alert('Copied the text: ' + inputElement.value)
  }
}
