import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['totalFee', 'totalPayout']

  connect() {
    document.querySelectorAll('.tenure-option').forEach((elm) => {
      elm.addEventListener('change', this.handleChangeTenure.bind(this))
    })
  }

  handleChangeTenure(event) {
    this.totalFeeTarget.innerHTML = event.currentTarget.getAttribute('data-fee')
    this.totalPayoutTarget.innerHTML =
      event.currentTarget.getAttribute('data-payout')
  }
}
