import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['']

  connect() {
    HSCore.components.HSTomSelect.init('.js-select', {
      onChange: function (val) {
        window.location.href = val
      },
    })
  }
}
