import { Controller } from '@hotwired/stimulus'
import intlTelInput from 'intl-tel-input'
import 'intl-tel-input/build/js/utils'
import $ from 'jquery'
import 'bootstrap-datepicker'

export default class extends Controller {
  static targets = [
    'companyPhoneInput',
    'companyValidMsg',
    'companyErrorMsg',
    'directorPhoneInput',
    'directorValidMsg',
    'directorErrorMsg',
    'kycForm',
  ]

  static errorMap = [
    'The phone number is invalid, please re-enter',
    'Invalid country code',
    'The phone number is too short, please re-enter',
    'The phone number is too long, please re-enter',
    'The phone number is invalid number, please re-enter',
  ]

  connect() {
    window.addEventListener('load', () => this._loadCompanyPhoneInput())
    window.addEventListener('load', () => this._loadDirectorPhoneInput())

    this._kycFormValidation()
    this._loadCompanyPhoneInput()
    this._loadDirectorPhoneInput()
  }

  _loadCompanyPhoneInput() {
    if (this.itiCompany) return

    this.itiCompany = new intlTelInput(this.companyPhoneInputTarget, {
      formatOnInit: true,
      separateDialCode: true,
      initialCountry: this.data.get('country'),
      preferredCountries: [this.data.get('country')],
      hiddenInput: 'phone_number',
      formatOnDisplay: true,
    })

    this.companyPhoneInputTarget.addEventListener('blur', () =>
      this._validateCompanyInput(),
    )
    this.companyPhoneInputTarget.addEventListener(
      'change',
      () => this._resetCompanyValidations,
    )
    this.companyPhoneInputTarget.addEventListener(
      'keyup',
      () => this._resetCompanyValidations,
    )
  }

  _loadDirectorPhoneInput() {
    if (this.itiDirector) return

    this.itiDirector = new intlTelInput(this.directorPhoneInputTarget, {
      formatOnInit: true,
      separateDialCode: true,
      initialCountry: this.data.get('country'),
      preferredCountries: [this.data.get('country')],
      hiddenInput: 'phone_number',
      formatOnDisplay: true,
    })

    this.directorPhoneInputTarget.addEventListener('blur', () =>
      this._validateDirectorInput(),
    )
    this.directorPhoneInputTarget.addEventListener(
      'change',
      () => this._resetCompanyValidations,
    )
    this.directorPhoneInputTarget.addEventListener(
      'keyup',
      () => this._resetCompanyValidations,
    )
  }

  _validateCompanyInput() {
    this._resetCompanyValidations()

    if (!this.companyPhoneInputTarget.value.trim()) {
      return false
    }

    if (this.itiCompany.isValidNumber()) {
      this.companyValidMsgTarget.classList.remove('hide')
      return true
    } else {
      const errorCode = this.itiCompany.getValidationError()

      this.companyPhoneInputTarget.classList.add('field_with_errors')
      this.companyErrorMsgTarget.innerHTML =
        this.constructor.errorMap[errorCode] || this.constructor.errorMap[0]
      this.companyErrorMsgTarget.classList.remove('hide')
      return false
    }
  }

  _validateDirectorInput() {
    this._resetDirectorValidations()

    if (!this.directorPhoneInputTarget.value.trim()) {
      return false
    }

    if (this.itiDirector.isValidNumber()) {
      this.directorValidMsgTarget.classList.remove('hide')
      return true
    } else {
      const errorCode = this.itiDirector.getValidationError()

      this.directorPhoneInputTarget.classList.add('field_with_errors')
      this.directorErrorMsgTarget.innerHTML =
        this.constructor.errorMap[errorCode] || this.constructor.errorMap[0]
      this.directorErrorMsgTarget.classList.remove('hide')
      return false
    }
  }

  _resetCompanyValidations() {
    this.companyPhoneInputTarget.classList.remove('field_with_errors')
    this.companyErrorMsgTarget.innerHTML = ''
    this.companyErrorMsgTarget.classList.add('hide')
    this.companyValidMsgTarget.classList.add('hide')
  }

  _resetDirectorValidations() {
    this.directorPhoneInputTarget.classList.remove('field_with_errors')
    this.directorErrorMsgTarget.innerHTML = ''
    this.directorErrorMsgTarget.classList.add('hide')
    this.directorValidMsgTarget.classList.add('hide')
  }

  _kycFormValidation() {
    if (!this.kycFormTarget) return

    var self = this
    this.kycFormTarget.addEventListener('submit', function (e) {
      var isValid =
        self._validateCompanyInput() && self._validateDirectorInput()

      if (isValid) {
        document
          .querySelector('input[type=submit]')
          .setAttribute('disabled', true)
        return
      }

      e.preventDefault()
    })
  }
}
