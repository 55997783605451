import { Controller } from '@hotwired/stimulus'
import $ from 'jquery'
import 'select2'
import 'select2/dist/css/select2.css'
import intlTelInput from 'intl-tel-input'
import 'intl-tel-input/build/js/utils'
import {
  generatePresignedUrl,
  uploadFileInForm,
  areAllFilesUploaded,
} from '../../modules/s3_uploader'

export default class extends Controller {
  static targets = [
    'cartFormWrapper',
    'cartForm',
    'orderFile',
    'uploadOrder',
    'companySelection',
    'phoneInput',
    'validMsg',
    'errorMsg',
  ]

  static phoneErrorMap = [
    'The phone number is invalid, please re-enter',
    'Invalid country code',
    'The phone number is too short, please re-enter',
    'The phone number is too long, please re-enter',
    'The phone number is invalid number, please re-enter',
  ]

  connect() {
    this.bindHandleEvent()
  }

  bindHandleEvent() {
    this.cartFormTarget.addEventListener('submit', this.submitForm.bind(this))
    if (this.hasCompanySelectionTarget) {
      $(this.companySelectionTarget).select2({
        placeholder: 'Search for a company',
        minimumInputLength: 3,
        ajax: {
          url: '/orders/' + this.data.get('cart-id') + '/partner-search',
          dataType: 'json',
          processResults: function (data) {
            return {
              results: data.results.map(function (item) {
                return {
                  id: item.company_id,
                  text:
                    item.legal_name +
                    ' - ' +
                    item.email +
                    ' - ' +
                    item.phone_number +
                    ' - ' +
                    item.business_address,
                }
              }),
            }
          },
          cache: true,
        },
      })
      this._handlePurchaserSelection()
    }

    if (this.hasPhoneInputTarget) this._loadPhoneInput()

    window.addEventListener('popstate', this._handlePopState.bind(this))

    new HSAddField('.js-add-field', {
      addedField: function (field) {
        field.classList.add('order-item')

        // bind event to new added field
        field
          .querySelector('.s3-upload-item')
          .addEventListener('change', (e) => {
            generatePresignedUrl('orders/', e.target)
          })

        field.querySelectorAll('input').forEach((input) => {
          input.setAttribute('required', true)
        })
      },
    })

    if (this.hasUploadOrderTarget) {
      document.querySelectorAll('.btn-delete-order').forEach((btn) => {
        btn.addEventListener('click', this.handleDeleteOrder.bind(this))
      })
    }
  }

  handleDeleteOrder(event) {
    var orderItem = event.target.closest('.order-item')
    if (!orderItem) return

    orderItem.setAttribute('hidden', true)
    orderItem.setAttribute('deleted', true)
  }

  collectOrderData(form) {
    var orderData = []

    form.querySelectorAll('.order-item').forEach((item) => {
      const orderItem = {
        order_number: item.querySelector('.order-number').value,
        total_amount: item.querySelector('.total-amount').value,
      }

      const orderIdElement = item.querySelector('.order-id')
      if (orderIdElement) orderItem.order_id = orderIdElement.value

      const fileElement = item.querySelector('.s3-upload-item')
      if (fileElement) {
        const fileData = fileElement.getAttribute('data-file')
        orderItem.order_file = JSON.parse(fileData).key
      }

      const deletedElement = item.getAttribute('deleted')
      if (deletedElement) orderItem.deleted = true

      orderData.push(orderItem)
    })

    const inputElement = document.createElement('input')
    inputElement.type = 'hidden'
    inputElement.name = 'order_data'
    inputElement.value = JSON.stringify(orderData)

    form.appendChild(inputElement)
  }

  submitForm(event) {
    const form = event.currentTarget
    if (form.elements['is_ready']) return

    event.preventDefault()

    if (this.hasPhoneInputTarget) {
      var isPhoneValid = this._validateInput()
      if (!isPhoneValid) return

      // need to trigger this because we overrided form submission here
      // to assign full number to hidden input
      this.iti._handleHiddenInputSubmit()
    }

    // Specific for upload page
    if (this.hasUploadOrderTarget) {
      if (!areAllFilesUploaded(form)) {
        uploadFileInForm(form, () => {
          form.submit.disabled = false
          form.submit.click()
        })

        form.submit.disabled = true
        return
      }

      this.collectOrderData(form)
    }

    var self = this
    // Submit the form data via AJAX
    fetch(form.action, {
      method: form.method,
      body: new FormData(form),
      headers: {
        'x-requested-with': 'XMLHttpRequest',
      },
    })
      .then((response) => response.text())
      .then((newContent) => {
        self.cartFormWrapperTarget.outerHTML = newContent

        // Set the new URL
        history.pushState({}, '', self.cartFormTarget.action)

        self.bindHandleEvent()
      })
      .catch((error) => {
        console.error('Error:', error)
      })
  }

  _loadPhoneInput() {
    if (this.iti) return

    this.iti = new intlTelInput(this.phoneInputTarget, {
      formatOnInit: true,
      separateDialCode: true,
      initialCountry: this.data.get('country'),
      preferredCountries: [this.data.get('country')],
      hiddenInput: 'purchaser_company_phone',
      formatOnDisplay: true,
    })

    this.phoneInputTarget.addEventListener('blur', () => this._validateInput())
    this.phoneInputTarget.addEventListener(
      'change',
      () => this._resetValidationsreset,
    )
    this.phoneInputTarget.addEventListener(
      'keyup',
      () => this._resetValidationsreset,
    )
  }

  _validateInput() {
    if (!this.hasPhoneInputTarget) return

    this._resetValidations()

    if (!this.phoneInputTarget.value.trim()) {
      return true
    }

    if (this.iti.isValidNumber()) {
      this.validMsgTarget.classList.remove('hide')
      return true
    } else {
      const errorCode = this.iti.getValidationError()

      this.phoneInputTarget.classList.add('field_with_errors')
      this.errorMsgTarget.innerHTML =
        this.constructor.phoneErrorMap[errorCode] ||
        this.constructor.phoneErrorMap[0]
      this.errorMsgTarget.classList.remove('hide')
      return false
    }
  }

  _resetValidations() {
    this.phoneInputTarget.classList.remove('field_with_errors')
    this.errorMsgTarget.innerHTML = ''
    this.errorMsgTarget.classList.add('hide')
    this.validMsgTarget.classList.add('hide')
  }

  _handlePurchaserSelection() {
    const currentCompanyName = document.getElementById(
      'id_purchaser_company_name',
    ).value

    if (currentCompanyName) {
      this._toggleCompanySelection(false)
    } else {
      this._toggleCompanySelection(true)
    }

    $('.company-selection-toggle').on('click', (e) => {
      const displayTarget = e.target.getAttribute('data-display-target')

      this._toggleCompanySelection(displayTarget == '.company-selection')
    })
  }

  _toggleCompanySelection(companySelectionEnabled) {
    if (companySelectionEnabled) {
      $('.company-selection').removeClass('d-none')
      $('.company-add-info').addClass('d-none')
      $('#id_purchaser_company').prop('required', true)
      $('input[type=text]').prop('required', false)
    } else {
      $('.company-add-info').removeClass('d-none')
      $('.company-selection').addClass('d-none')
      $('#id_purchaser_company').prop('required', false)
      $('#id_purchaser_company_name').prop('required', true)
      // $('input[type=text]').prop('required', true)
    }
  }

  _handlePopState() {
    window.location.reload()
  }
}
