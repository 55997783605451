import { Controller } from '@hotwired/stimulus'
import intlTelInput from 'intl-tel-input'
import 'intl-tel-input/build/js/utils'
import $ from 'jquery'
import 'bootstrap-datepicker'
import {
  generatePresignedUrl,
  uploadFileInForm,
  areAllFilesUploaded,
} from '../../modules/s3_uploader'

export default class extends Controller {
  static targets = [
    'phoneInput',
    'validMsg',
    'errorMsg',
    'kycForm',
    'onlyBirthyear',
    'birthyearInput',
  ]

  static errorMap = [
    'The phone number is invalid, please re-enter',
    'Invalid country code',
    'The phone number is too short, please re-enter',
    'The phone number is too long, please re-enter',
    'The phone number is invalid number, please re-enter',
  ]

  connect() {
    window.addEventListener('load', () => this._loadPhoneInput())

    this._loadPhoneInput()
    this._handleFileUploadSize()
    this._handleGeneratePresignedUrl()

    this.kycFormTarget.addEventListener('submit', this.submitForm.bind(this))

    $('.datepicker').datepicker({
      format: 'dd/mm/yyyy',
      autoclose: true,
    })

    this.onlyBirthyearTarget.addEventListener('change', () => {
      if (this.onlyBirthyearTarget.checked) {
        this.birthyearInputTarget.classList.remove('d-none')
      } else {
        this.birthyearInputTarget.querySelector('input').value = ''
        this.birthyearInputTarget.classList.add('d-none')
      }
    })
  }

  _handleGeneratePresignedUrl() {
    document.querySelectorAll('input[type=file]').forEach((fileInput) => {
      fileInput.addEventListener('change', function (e) {
        generatePresignedUrl('director_kycs/', e.target)
      })
    })
  }

  _loadPhoneInput() {
    if (this.iti) return

    this.iti = new intlTelInput(this.phoneInputTarget, {
      formatOnInit: true,
      separateDialCode: true,
      initialCountry: this.data.get('country'),
      preferredCountries: [this.data.get('country')],
      hiddenInput: 'phone_number',
      formatOnDisplay: true,
    })

    this.phoneInputTarget.addEventListener('blur', () => this._validateInput())
    this.phoneInputTarget.addEventListener(
      'change',
      () => this._resetValidationsreset,
    )
    this.phoneInputTarget.addEventListener(
      'keyup',
      () => this._resetValidationsreset,
    )
  }

  _handleFileUploadSize() {
    document.querySelectorAll('input[type=file]').forEach((fileInput) => {
      fileInput.addEventListener('change', function () {
        const selectedFile = fileInput.files[0]
        if (!selectedFile) return

        const fileSize = selectedFile.size // in bytes
        const maxSize = 25 * 1024 * 1024 // 25MB in bytes

        if (fileSize >= maxSize) {
          alert(
            'File size exceeds the maximum allowed size of 25MB. Please select a smaller file.',
          )

          fileInput.value = ''
        }
      })
    })
  }

  _validateInput() {
    this._resetValidations()

    if (!this.phoneInputTarget.value.trim()) {
      return false
    }

    if (this.iti.isValidNumber()) {
      this.validMsgTarget.classList.remove('hide')
      return true
    } else {
      const errorCode = this.iti.getValidationError()

      this.phoneInputTarget.classList.add('field_with_errors')
      this.errorMsgTarget.innerHTML =
        this.constructor.errorMap[errorCode] || this.constructor.errorMap[0]
      this.errorMsgTarget.classList.remove('hide')
      return false
    }
  }

  _resetValidations() {
    this.phoneInputTarget.classList.remove('field_with_errors')
    this.errorMsgTarget.innerHTML = ''
    this.errorMsgTarget.classList.add('hide')
    this.validMsgTarget.classList.add('hide')
  }

  submitForm(e) {
    const self = this
    const form = self.kycFormTarget

    var isValid = self._validateInput()
    if (!isValid) {
      e.preventDefault()
      return
    }

    if (areAllFilesUploaded(form)) {
      return
    }

    e.preventDefault()
    form.submit.disabled = true

    uploadFileInForm(form, () => {
      form.submit.disabled = false
      form.submit.click()
    })
  }
}
