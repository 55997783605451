import intlTelInput from 'intl-tel-input'

const phoneErrorMap = [
  'The phone number is invalid, please re-enter',
  'Invalid country code',
  'The phone number is too short, please re-enter',
  'The phone number is too long, please re-enter',
  'The phone number is invalid number, please re-enter',
]

function validateInput(iti, phoneInputTarget, errorMsgTarget, validMsgTarget) {
  resetValidations(phoneInputTarget, errorMsgTarget, validMsgTarget)

  if (!phoneInputTarget.value.trim()) {
    return true
  }

  if (iti.isValidNumber()) {
    validMsgTarget.classList.remove('hide')
    return true
  } else {
    const errorCode = iti.getValidationError()

    phoneInputTarget.classList.add('field_with_errors')
    errorMsgTarget.innerHTML = phoneErrorMap[errorCode] || phoneErrorMap[0]
    errorMsgTarget.classList.remove('hide')
    return false
  }
}

function resetValidations(phoneInputTarget, errorMsgTarget, validMsgTarget) {
  phoneInputTarget.classList.remove('field_with_errors')
  errorMsgTarget.innerHTML = ''
  errorMsgTarget.classList.add('hide')
  validMsgTarget.classList.add('hide')
}

export function loadPhoneInput(
  phoneInputTarget,
  country,
  errorMsgTarget,
  validMsgTarget,
) {
  const iti = new intlTelInput(phoneInputTarget, {
    formatOnInit: true,
    separateDialCode: true,
    initialCountry: country,
    preferredCountries: [country],
    hiddenInput: 'phone_input',
    formatOnDisplay: true,
  })

  phoneInputTarget.addEventListener('blur', () => {
    validateInput(iti, phoneInputTarget, errorMsgTarget, validMsgTarget)
  })
  phoneInputTarget.addEventListener('change', () => {
    resetValidations(phoneInputTarget, errorMsgTarget, validMsgTarget)
  })
  phoneInputTarget.addEventListener('keyup', () => {
    resetValidations(phoneInputTarget, errorMsgTarget, validMsgTarget)
  })

  return iti
}
